import React from 'react';

import { BarClients } from '../SideBar/BarClients';
import { TClient } from '../tools/types';

import styles from './MobileMenu.module.css';

type TProps = {
  Clients: TClient[];
  currentClientId: string;
  currentGroupId: string;
  currentTestId: string;

  isMobileMenuOpen: boolean;

  setCurrentClientId: (value: string) => void;
  setCurrentGroupId: (value: string) => void;
  setCurrentTestId: (value: string) => void;

  setIsMobileMenuOpen: (value: boolean) => void;
};

export const MobileMenu = (props: TProps) => {
  const {
    Clients,

    currentClientId,
    currentGroupId,
    currentTestId,
    setCurrentClientId,
    setCurrentGroupId,
    setCurrentTestId,

    isMobileMenuOpen,
    setIsMobileMenuOpen,
  } = props;

  return (
    <div
      className={isMobileMenuOpen ? styles.showMobileMenu : styles.mobileMenu}
    >
      <BarClients
        Clients={Clients}
        setIsMenuOpen={setIsMobileMenuOpen}
        currentClientId={currentClientId}
        currentGroupId={currentGroupId}
        currentTestId={currentTestId}
        setCurrentClientId={setCurrentClientId}
        setCurrentGroupId={setCurrentGroupId}
        setCurrentTestId={setCurrentTestId}
      />
    </div>
  );
};

import { useEffect, useState } from 'react';

import { BarGroups } from '../BarGroups';
import { TClient } from '../tools/types';

import styles from './ClientLinkBox.module.css';

type TProps = {
  Clients: TClient[];
  client: TClient;
  currentClientId: string;
  currentGroupId: string;
  currentTestId: string;
  setCurrentClientId: (value: string) => void;
  setCurrentGroupId: (value: string) => void;
  setCurrentTestId: (value: string) => void;

  setIsMenuOpen: (value: boolean) => void;
};

export const ClientBox = (props: TProps) => {
  const {
    client,
    setIsMenuOpen,
    currentClientId,
    currentGroupId,
    currentTestId,
    setCurrentClientId,
    setCurrentGroupId,
    setCurrentTestId,
  } = props;

  const [isOpenedBox, setIsOpenedBox] = useState(false);

  const isActive = client.Id.toString() === currentClientId;

  useEffect(() => {
    setIsOpenedBox(isActive);
  }, [currentClientId]);

  const onClientClick = () => {
    const clientId = client.Id.toString();

    localStorage.setItem('initClientId', clientId);
    localStorage.setItem('initGroupId', '');
    localStorage.setItem('initTestId', '');

    setIsOpenedBox(!isOpenedBox);
    setCurrentClientId(clientId);
  };

  return (
    <li>
      <div
        className={isActive ? styles.active : styles.inactive}
        onClick={onClientClick}
        style={{
          background: isOpenedBox
            ? 'linear-gradient(90deg, #FFED00 0%, #FFED00 3%, #FBFBFB 3%, #FBFBFB 100%)'
            : '',
        }}
      >
        {client.Name}
      </div>

      {isOpenedBox && (
        <BarGroups
          groups={client.Groups}
          setIsMenuOpen={setIsMenuOpen}
          currentGroupId={currentGroupId}
          currentTestId={currentTestId}
          setCurrentGroupId={setCurrentGroupId}
          setCurrentTestId={setCurrentTestId}
        />
      )}
    </li>
  );
};

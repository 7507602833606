import React from 'react';

import { getMarkBackgroundColor } from '../../../tools/helpers';
import { TResult } from '../../../tools/types';

import styles from './Review.module.css';

type TProps = {
  resultData: TResult;
};

export const Review = (props: TProps) => {
  const { resultData } = props;
  const { Mark, MarkDescription, SkillName } = resultData;

  return (
    <div className={styles.review}>
      <div className={styles.reviewName}>{SkillName}</div>

      {MarkDescription}

      <div
        className={`${styles.reviewLabel}`}
        style={{ backgroundColor: getMarkBackgroundColor(Mark) }}
      >
        {Mark}
      </div>
    </div>
  );
};

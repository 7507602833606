import { TClient, TGroup } from './types';

export const getBackgroundColors = (mark: string) => {
  switch (mark.toLowerCase()) {
    case 'отлично':
      return { empty: '#ffface', finish: '#FFEB02', start: '#FFD32A' };
    case 'хорошо':
      return { empty: '#f5fdeb', finish: '#B0E186', start: '#E2F289' };

    case 'не сдал':
      return { empty: '#F0F0F0', finish: '#E0E0E0', start: '#C3C3C3' };

    default: // 'неплохо'
      return { empty: '#f6ebff', finish: '#9162D9', start: '#C3A5EF' };
  }
};

export const getMarkBackgroundColor = (mark: string) => {
  switch (mark.toLowerCase()) {
    case 'отлично':
      return '#FFEE02';
    case 'хорошо':
      return '#CDF576';

    case 'не сдал':
      return '#D8D8D8';

    default: // 'неплохо'
      return '#E2C8FA';
  }
};

export const getClientsSortedData = (clientsData: TClient[]) => {
  const result: TClient[] = clientsData.map((client: TClient) => {
    const updatedGroups: TGroup[] = client.Groups.map((group: TGroup) => {
      const sortedUnitTests = [...group.UnitTests].sort((a, b) => {
        if (b.IsFinal) {
          return -1;
        }

        if (a.IsFinal) {
          return 1;
        }

        if (!(a.UnitNumber === null) && !(b.UnitNumber === null)) {
          return a.UnitNumber - b.UnitNumber;
        }

        return 0;
      });

      return { ...group, UnitTests: sortedUnitTests };
    });

    return { ...client, Groups: updatedGroups };
  });

  return result;
};

export const getIds = (clientsData: TClient[], testId: string) => {
  const result = ['', '', testId];

  clientsData.forEach((client, clientIndex) => {
    client.Groups.forEach((group, groupIndex) => {
      const testIndex = group.UnitTests.findIndex(
        (test) => test.Id.toString() === testId,
      );

      if (testIndex !== -1) {
        result[1] = client.Groups[groupIndex].Id.toString();
        result[0] = clientsData[clientIndex].Id.toString();
      }
    });
  });

  return result;
};

import React from 'react';

import { getBackgroundColors } from '../tools/helpers';

type TProps = {
  Mark: string;
  idCSS: string;
};

const gradientTransform = `rotate(90)`;

export const GradientSVG = (props: TProps) => {
  const { Mark, idCSS } = props;
  const { start, finish } = getBackgroundColors(Mark);

  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient
          id={idCSS}
          gradientTransform={gradientTransform}
        >
          <stop
            offset="16.29%"
            stopColor={start}
          />
          <stop
            offset="85.56%"
            stopColor={finish}
          />
        </linearGradient>
      </defs>
    </svg>
  );
};

import React from 'react';

import { UnitBox } from '../UnitBox';
import { TGroup } from '../tools/types';

import styles from './NavClientGroup.module.css';

type TProps = {
  currentGroupId: string;
  currentTestId: string;

  groups: TGroup[];

  setCurrentGroupId: (value: string) => void;
  setCurrentTestId: (value: string) => void;

  setIsMenuOpen: (value: boolean) => void;
};

export const BarGroups = (props: TProps) => {
  const {
    groups,
    setIsMenuOpen,
    currentGroupId,
    currentTestId,
    setCurrentGroupId,
    setCurrentTestId,
  } = props;

  return (
    <ul className={styles.groupsMenu}>
      {groups.map((el) => (
        <UnitBox
          group={el}
          key={el.Id}
          setIsMenuOpen={setIsMenuOpen}
          currentGroupId={currentGroupId}
          currentTestId={currentTestId}
          setCurrentGroupId={setCurrentGroupId}
          setCurrentTestId={setCurrentTestId}
        />
      ))}
    </ul>
  );
};

import React from 'react';

import openCloseBtn from '../assets/img/openCloseBtn.png';

import styles from './index.module.scss';

type TProps = {
  isOpenSideBar: boolean;
  setIsOpenSideBar: (value: boolean) => void;
};

export const FooterBtnBox = (props: TProps) => {
  const { isOpenSideBar, setIsOpenSideBar } = props;

  const btnStyle = isOpenSideBar ? { transform: 'rotate(180deg)' } : {};

  const openCloseSideBar = () => {
    setIsOpenSideBar(!isOpenSideBar);
  };

  return (
    <div className={styles.FooterBtnBox}>
      <button
        type="button"
        onClick={openCloseSideBar}
      >
        <img
          className={styles.OpenCloseBtn}
          style={btnStyle}
          alt="OpenCloseBtn"
          src={openCloseBtn}
        />
      </button>
    </div>
  );
};

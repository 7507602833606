import './App.scss';

import React, { useEffect, useState } from 'react';

import { SideBar } from './SideBar';
import { MainContent } from './pages/Contact';
import { getClientsSortedData, getIds } from './tools/helpers';
import { TClient } from './tools/types';

export const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get('token');
  const urlTestId = urlParams.get('uresid');

  if (token) {
    localStorage.setItem('token', token);
  } else {
    token = localStorage.getItem('token');
  }

  const apiHost =
    window.location.hostname === 'my.ankor-center.ru'
      ? 'bot.ankor-center.ru'
      : 'test-bot.ankor-center.ru';

  let initClientId = '';
  let initGroupId = '';
  let initTestId = '';

  if (localStorage.getItem('initTestId') !== null && !urlTestId) {
    // @ts-ignore
    initClientId = localStorage.getItem('initClientId');
    // @ts-ignore
    initGroupId = localStorage.getItem('initGroupId');
    // @ts-ignore
    initTestId = localStorage.getItem('initTestId');
  }

  const [currentClientId, setCurrentClientId] = useState<string>(initClientId);
  const [currentGroupId, setCurrentGroupId] = useState<string>(initGroupId);
  const [currentTestId, setCurrentTestId] = useState<string>(initTestId);
  const [Clients, setClients] = useState<TClient[]>([]);

  useEffect(() => {
    fetch(`https://${apiHost}/api/myankor/unitresults?token=${token}`).then(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data) => {
            const clientsSortedData: TClient[] = getClientsSortedData(
              data.Clients,
            );
            setClients(clientsSortedData);

            if (urlTestId) {
              const Ids = getIds(clientsSortedData, urlTestId);

              localStorage.setItem('initClientId', Ids[0]);
              localStorage.setItem('initGroupId', Ids[1]);
              localStorage.setItem('initTestId', Ids[2]);

              setCurrentClientId(Ids[0]);
              setCurrentGroupId(Ids[1]);
              setCurrentTestId(Ids[2]);
            }
          });
        } else if (response.status === 401) {
          alert('Ссылка недействительная, запросите у бота новую');
        } else {
          alert('Не удалось загрузить данные');
        }
      },
    );
  }, []);

  useEffect(() => {
    setCurrentClientId(initClientId || Clients[0]?.Id.toString() || '');
    setCurrentGroupId(
      initGroupId || Clients[0]?.Groups[0]?.Id.toString() || '',
    );
    setCurrentTestId(
      initTestId || Clients[0]?.Groups[0]?.UnitTests[0]?.Id.toString() || '',
    );
  }, [Clients.length]);

  useEffect(() => {
    const initGroupId = localStorage.getItem('initGroupId');

    const curIndex = Clients.findIndex(
      (el) => el.Id.toString() === currentClientId,
    );
    setCurrentGroupId(
      initGroupId || Clients[curIndex]?.Groups[0]?.Id.toString() || '',
    );
  }, [currentClientId]);

  useEffect(() => {
    const initTestId = localStorage.getItem('initTestId');
    const curIndex = Clients.findIndex(
      (el) => el.Id.toString() === currentClientId,
    );
    const curGroupsIndex = Clients[curIndex]?.Groups.findIndex(
      (el) => el.Id.toString() === currentGroupId,
    );
    const TestId =
      initTestId ||
      Clients[curIndex]?.Groups[curGroupsIndex]?.UnitTests[0]?.Id.toString() ||
      '';

    setCurrentTestId(TestId);
  }, [currentClientId, currentGroupId]);

  useEffect(() => {
    if (currentTestId) {
      localStorage.setItem('initClientId', currentClientId);
      localStorage.setItem('initGroupId', currentGroupId);
      localStorage.setItem('initTestId', currentTestId);
    }
  }, [currentTestId]);

  return (
    <div className="App">
      <MainContent
        Clients={Clients}
        currentClientId={currentClientId}
        currentGroupId={currentGroupId}
        currentTestId={currentTestId}
        setCurrentClientId={setCurrentClientId}
        setCurrentGroupId={setCurrentGroupId}
        setCurrentTestId={setCurrentTestId}
      />

      <SideBar
        Clients={Clients}
        currentClientId={currentClientId}
        currentGroupId={currentGroupId}
        currentTestId={currentTestId}
        setCurrentClientId={setCurrentClientId}
        setCurrentGroupId={setCurrentGroupId}
        setCurrentTestId={setCurrentTestId}
      />
    </div>
  );
};

import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import { getBackgroundColors } from '../tools/helpers';
import { TResult } from '../tools/types';
import { GradientSVG } from '../utils/gradientSVG';

import 'react-circular-progressbar/dist/styles.css';
import styles from './CircularDiagram.module.css';

type TProps = {
  resultData: TResult;
};

export const CircularDiagram = (props: TProps) => {
  const { resultData } = props;
  const { Score, MaxScore, Mark, SkillName, SkillId } = resultData;

  const percentage = (Score / MaxScore) * 100;
  const idCSS = SkillId.toString();
  const { empty } = getBackgroundColors(Mark);

  return (
    <div className={styles.diagram}>
      <GradientSVG
        Mark={Mark}
        idCSS={idCSS}
      />

      <CircularProgressbar
        value={percentage}
        text={`${Score}/${MaxScore}`}
        strokeWidth={15}
        styles={{
          background: {
            fill: '#ffffff',
          },
          path: {
            stroke: `url(#${idCSS})`,
            strokeLinecap: 'butt',
            transformOrigin: 'center center',
            transition: 'stroke-dashoffset 0.5s ease 0s',
          },
          root: {},
          text: {
            fill: '#000000',
            fontSize: '14px',
          },
          trail: {
            stroke: empty,
            strokeLinecap: 'butt',
            transform: 'rotate(0.25turn)',
            transformOrigin: 'center center',
          },
        }}
      />

      <p className={styles.diagramDescription}>{SkillName}</p>
    </div>
  );
};

import { useState } from 'react';

import { CircularDiagram } from '../../CircularDiagram';
import { Header } from '../../Header';
import { TProps } from '../../tools/types';

import styles from './Contact.module.css';
import { Review } from './Review';

export const MainContent = (props: TProps) => {
  const {
    Clients,
    currentClientId,
    currentGroupId,
    currentTestId,
    setCurrentClientId,
    setCurrentGroupId,
    setCurrentTestId,
  } = props;

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  if (!Clients.length) return;

  const currentClient = Clients.find(
    (el) => el.Id.toString() === currentClientId,
  );
  if (!currentClient) return;

  const currentGroup = currentClient.Groups.find(
    (el) => el.Id.toString() === currentGroupId,
  );
  if (!currentGroup) return;

  const currentUnit = currentGroup.UnitTests.find(
    (el) => el.Id.toString() === currentTestId,
  );
  if (!currentUnit) return;

  const results = currentUnit.Results;
  const [resultsCount, resultsTotal] = results.reduce(
    (acc, currentValue) => {
      acc[0] += currentValue.Score;
      acc[1] += currentValue.MaxScore;

      return acc;
    },
    [0, 0],
  );

  const currentUnitName = currentUnit.IsFinal
    ? 'Финальный тест'
    : `Юнит: ${currentUnit.UnitNumber}`;

  return (
    <div>
      <Header
        Clients={Clients}
        currentClientId={currentClientId}
        currentGroupId={currentGroupId}
        currentTestId={currentTestId}
        isMobileMenuOpen={isMobileMenuOpen}
        setCurrentClientId={setCurrentClientId}
        setCurrentGroupId={setCurrentGroupId}
        setCurrentTestId={setCurrentTestId}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
      />

      <div
        id="contact"
        className={styles.main}
      >
        <p className={styles.name}>{currentClient.Name}</p>

        <div className={styles.contactData}>
          <p>ID : {currentClient.Code}</p>

          <p>группа {currentGroup.Id}</p>
        </div>

        <div className={styles.classesData}>
          <p>{currentUnitName}</p>

          <p className={styles.classesDataCount}>
            Посещено занятий: {currentUnit.AttendedLessons}/
            {currentUnit.TotalLessons}
          </p>
        </div>

        <div className={styles.learningData}>
          <p className={styles.learningProgram}>
            Программа: {currentGroup.LevelGroupName}
          </p>

          <div className={styles.learningData_total}>
            <p className={styles.learningData_count}>
              Total: {resultsCount}/{resultsTotal}
            </p>
          </div>
        </div>

        <div className={styles.diagrams}>
          {results.map((el) => (
            <CircularDiagram
              key={el.SkillId}
              resultData={el}
            />
          ))}
        </div>

        <div>
          {results.map((el) => (
            <Review
              key={el.SkillId}
              resultData={el}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';

import userIcon from '../assets/img/userIcon.png';
import { TProps } from '../tools/types';

import { BarClients } from './BarClients';
import { FooterBtnBox } from './FooterBtnBox';
import styles from './index.module.scss';

export const SideBar = (props: TProps) => {
  const {
    Clients,
    currentClientId,
    currentGroupId,
    currentTestId,
    setCurrentClientId,
    setCurrentGroupId,
    setCurrentTestId,
  } = props;

  const [isOpenSideBar, setIsOpenSideBar] = useState<boolean>(true);

  const SideBarStyle = isOpenSideBar ? {} : { transform: 'translate(-230px)' };
  const UserIconStyle = isOpenSideBar ? {} : { transform: 'translate(230px)' };

  return (
    <div
      className={styles.SideBar}
      style={SideBarStyle}
    >
      <img
        className={styles.UserIcon}
        style={UserIconStyle}
        alt="UserIcon"
        src={userIcon}
      />

      {isOpenSideBar && (
        <BarClients
          Clients={Clients}
          currentClientId={currentClientId}
          currentGroupId={currentGroupId}
          currentTestId={currentTestId}
          setCurrentClientId={setCurrentClientId}
          setCurrentGroupId={setCurrentGroupId}
          setCurrentTestId={setCurrentTestId}
          setIsMenuOpen={setIsOpenSideBar}
        />
      )}

      <FooterBtnBox
        isOpenSideBar={isOpenSideBar}
        setIsOpenSideBar={setIsOpenSideBar}
      />
    </div>
  );
};

import React, { useEffect, useState } from 'react';

import { TGroup } from '../tools/types';

import styles from './UnitBox.module.css';

type TProps = {
  currentGroupId: string;
  currentTestId: string;
  group: TGroup;

  setCurrentGroupId: (value: string) => void;
  setCurrentTestId: (value: string) => void;

  setIsMenuOpen: (value: boolean) => void;
};

export const UnitBox = (props: TProps) => {
  const {
    group,
    setIsMenuOpen,
    currentGroupId,
    currentTestId,
    setCurrentGroupId,
    setCurrentTestId,
  } = props;

  const [isOpenSubSubMenu, setIsOpenSubSubMenu] = useState(false);

  const isActive = group.Id.toString() === currentGroupId;

  useEffect(() => {
    setIsOpenSubSubMenu(isActive);
  }, [currentGroupId]);

  const scrollToTop = () => {
    window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
  };

  const onClickGroup = () => {
    const groupId = group.Id.toString();

    localStorage.setItem('initGroupId', groupId);
    localStorage.setItem('initTestId', '');

    setIsOpenSubSubMenu(!isOpenSubSubMenu);
    setCurrentGroupId(groupId);
    scrollToTop();
  };

  const onClickTest = (testId: string) => {
    localStorage.setItem('initTestId', testId);

    setIsMenuOpen(false);
    setCurrentTestId(testId.toString());
    scrollToTop();
  };

  return (
    <li>
      <div
        onClick={onClickGroup}
        className={styles.submenu}
        style={{
          background: isActive ? '#9f9f9f' : '',
          borderImage: isActive ? 'none' : '',
        }}
      >
        {group.Name}
      </div>

      {isOpenSubSubMenu && (
        <ul className={styles.subsubmenuBox}>
          {group.UnitTests.map((el) => {
            const isCurrent = currentTestId === el.Id.toString();

            return (
              <li
                key={el.Id}
                onClick={() => onClickTest(el.Id.toString())}
                className={styles.subsublink}
                style={{
                  color: isCurrent ? '#000000' : '',
                  paddingLeft: isCurrent ? '79px' : '',
                }}
              >
                {el.IsFinal ? 'Финальный тест' : `Юнит ${el.UnitNumber}`}
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
};

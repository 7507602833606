import React from 'react';

import { TClient } from '../tools/types';

import styles from './BarClients.module.css';
import { ClientBox } from './ClientBox';

type TProps = {
  Clients: TClient[];
  currentClientId: string;

  currentGroupId: string;
  currentTestId: string;
  setCurrentClientId: (value: string) => void;

  setCurrentGroupId: (value: string) => void;
  setCurrentTestId: (value: string) => void;
  setIsMenuOpen: (value: boolean) => void;
};

export const BarClients = (props: TProps) => {
  const {
    Clients,
    setIsMenuOpen,
    currentClientId,
    currentGroupId,
    currentTestId,
    setCurrentClientId,
    setCurrentGroupId,
    setCurrentTestId,
  } = props;

  return (
    <nav className={styles.navBox}>
      <ul className={styles.navUl}>
        {Clients.map((el) => (
          <ClientBox
            client={el}
            Clients={Clients}
            key={el.Id}
            setIsMenuOpen={setIsMenuOpen}
            currentClientId={currentClientId}
            currentGroupId={currentGroupId}
            currentTestId={currentTestId}
            setCurrentClientId={setCurrentClientId}
            setCurrentGroupId={setCurrentGroupId}
            setCurrentTestId={setCurrentTestId}
          />
        ))}
      </ul>
    </nav>
  );
};

import React from 'react';

import { MobileMenu } from '../MobileMenu';
import logo from '../assets/img/logo.svg';
import phone from '../assets/img/phone.svg';
import { TClient } from '../tools/types';

import styles from './Header.module.css';

type TProps = {
  Clients: TClient[];

  currentClientId: string;
  currentGroupId: string;
  currentTestId: string;
  isMobileMenuOpen: boolean;

  setCurrentClientId: (value: string) => void;
  setCurrentGroupId: (value: string) => void;
  setCurrentTestId: (value: string) => void;
  setIsMobileMenuOpen: (value: boolean) => void;
};

export const Header = (props: TProps) => {
  const {
    Clients,
    currentClientId,
    currentGroupId,
    currentTestId,
    isMobileMenuOpen,
    setCurrentClientId,
    setCurrentGroupId,
    setCurrentTestId,
    setIsMobileMenuOpen,
  } = props;

  const onClick = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      <div className={styles.header}>
        <img
          src={logo}
          alt="logo"
        />

        <div className={styles.phoneContainer}>
          <img
            src={phone}
            alt="phone"
          />

          <a href="tel:+78352685808">68-58-08</a>

          <div
            className={styles.burger}
            onClick={onClick}
          >
            <div
              className={
                isMobileMenuOpen ? styles.firstLine : styles.burgerLine
              }
            />

            <div
              className={
                isMobileMenuOpen ? styles.hidingLine : styles.burgerLine
              }
            />

            <div
              className={isMobileMenuOpen ? styles.lastLine : styles.burgerLine}
            />
          </div>
        </div>

        <MobileMenu
          Clients={Clients}
          currentClientId={currentClientId}
          currentGroupId={currentGroupId}
          currentTestId={currentTestId}
          setCurrentClientId={setCurrentClientId}
          setCurrentGroupId={setCurrentGroupId}
          setCurrentTestId={setCurrentTestId}
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        />
      </div>
    </div>
  );
};
